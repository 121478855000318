<template>
  <div class="view-wrapper this-wrapper">
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="amount"
          label="Лимит, сумма"
          hide-details
          outlined
          dense
          readonly

          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-text-field
          v-model="spent"
          label="Потрачено, сумма"
          hide-details
          outlined
          dense
          readonly
          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-text-field
          v-model="difference"
          label="Остаток, сумма"
          hide-details
          outlined
          dense

          readonly
          :background-color="differenceWarning"
          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-text-field
          v-model="discountedDifference"
          label="Остаток, сумма с учетом скидки"
          hide-details
          outlined
          dense
          readonly
          :background-color="differenceWarning"
          style="max-width: 168px; margin: 0 2px"
        ></v-text-field>
        <v-spacer/>
        <v-btn icon @click="refresh">
          <v-icon>refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-value="categoryId"
        item-key="categoryId"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        :item-class="itemColor"
        @click:row="rowClick"
      >
        <template v-slot:item.amountDifference="{ item }">
          <b>
            {{ item.amountDifference }}
          </b>
        </template>
        <template v-slot:item.quantityDifference="{ item }">
          <b>
            {{ item.quantityDifference }}
          </b>
        </template>
      </v-data-table>
    </div>
    <div v-if="item" class="view">
      <Detail :vm="this"></Detail>
    </div>
  </div>
</template>

<script>
  import fetch from "@/utils/fetch";
  import ctx from "@/ctx";
  import Detail from "./Detail";
  import vm2js from "@/utils/vm2js";




  export default {
    props: {
      vm: Object,
      insuredId: Number,
      mainAmount: {
        type: Number,
        default: 0
      }
    },
    data: () => ({
      ctx,
      currentPage: 0,
      totalPages: 0,
      loading: false,
      selected: [],
      headers: [
        {
          text: "Категории / Услуги",
          sortable: true,
          value: "serviceName"
        },
        {text: "Лимит, сумма", value: "amountLimit"},
        {text: "Израсходавано, сумма", sortable: false, value: "amountSpent"},
        {text: "Остаток, сумма", sortable: false, value: "amountDifference"},
        {text: "Лимит, кол-во", sortable: false, value: "quantityLimit"},
        {
          text: "Израсходавано, кол-во",
          sortable: false,
          value: "quantitySpent"
        },
        {text: "Остаток, кол-во", sortable: false, value: "quantityDifference"}
      ],
      items: [],
      item: undefined,
      amount: undefined,
      spent: undefined,
      difference: undefined,
      discountedDifference: undefined
    }),
    watch: {
      insuredId: function (val) {
        this.selected = [];
        this.items = [];
        if (val) return this.fetchItems();
      },
      selected: function (val) {
        if (val.length === 0) this.item = undefined;
        else {
          this.item = vm2js(val[0]);
        }
      }
    },
    methods: {
      itemColor(item) {
        if (
          (item.amountDifference < 0) ||
          (item.quantityDifference < 0 )
        )
          return "item-red";
        return "";
      },



      close() {
        this.selected = [];
      },
      async fetchItems() {
        this.close();
        this.items = [];
        this.loading = true;
        let res = await fetch.get(
          "/api/accounting/balance/get-balance/" +
          this.insuredId

        );


        if (res) {
          this.amount = res.amount;
          this.spent = res.spent;
          this.difference = res.difference;
          this.discountedDifference = res.discountedDifference;

          this.items = res.limitsDto;

          this.sortItems();

        }
        this.loading = false;
      },

      sortItems() {
        let unspecified = this.items.find(item => item.isUnspecified);
        if(!unspecified) return;
        this.items = this.items.filter(item => !item.isUnspecified);
        this.items.push(unspecified);
      }

      ,
      rowClick(obj, row) {
        if (!row.isSelected) row.select(obj);
        else this.selected = [];
      },
      refresh() {
        this.fetchItems();
      }
    },

    computed: {


      differenceWarning() {
        return this.discountedDifference < 100000 ? "yellow" : "";
      }
    },

    async mounted() {
      if (this.vm) this.vm.balance = this;
      this.loading = true;
      await this.fetchItems();

      this.loading = false;

    },
    components: {
      Detail
    }
  };
</script>

<style lang="scss" scoped>
  .this-wrapper {
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }



</style>
