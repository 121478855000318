<template>
  <div class="view" style="overflow: hidden">
    <v-toolbar class="my-bar">
      <v-text-field
        v-if="vm.item.serviceId"
        v-model="vm.item.quantity"
        label="Лимит, количество"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>
      <v-text-field
        v-else
        v-model="vm.item.amountLimit"
        label="Лимит, сумма"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>

      <v-text-field
        v-if="vm.item.serviceId"
        v-model="vm.item.quantityDifference"
        label="Остаток, количество"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>
      <v-text-field
        v-if="!vm.item.serviceId"
        v-model="vm.item.amountDifference"
        label="Остаток, сумма"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>
      <v-text-field
        v-if="!vm.item.serviceId"
        v-model="vm.item.amountDiscountedDifference"
        label="Остаток со скидкой, сумма"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>


      <v-text-field
        v-if="vm.item.serviceId"
        v-model="vm.item.quantitySpent"
        label="Израсходавано, количество"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>
      <v-text-field
        v-if="!vm.item.serviceId"
        v-model="vm.item.amountSpent"
        label="Израсходавано, сумма"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>
      <v-text-field
        v-if="!vm.item.serviceId"
        v-model="vm.item.amountDiscountedSpent"
        label="Израсходавано со скидкой, сумма"
        hide-details
        outlined
        dense
        readonly
        style="max-width: 168px; margin: 0 2px"
      ></v-text-field>

      <v-spacer />
      <v-btn icon @click="vm.close">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="-1"
      :single-select="true"
      item-key="id"
      :item-class="itemColor"
      hide-default-footer
      :loading="loading"
      loading-text="Загрузка... Пожалуйста подождите"
      fixed-header
    >
      <template v-slot:item.stateKey="{ item }">
        <v-icon v-if="item.stateKey === 'E'" color="green">done</v-icon>
        <v-icon v-else-if="item.stateKey === 'R'" color="blue"
        >arrow_right</v-icon
        >
        <v-icon v-else color="orange">arrow_left</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  import fetch from "@/utils/fetch";
  import js2vm from "@/utils/js2vm";
  import ctx from "@/ctx";
  import dateLocalFilter from "../../filters/dateLocal.filter";
  import dateIsoFilter from "../../filters/dateIso.filter";
  import moment from "moment";

  let Item = function(d) {
    let self = this;

    d.insured = d.insuredId;
    d.organization = d.organizationId;
    d.doctor = d.doctorId;
    d.diagnosis = d.diagnosisId;
    d.service = d.serviceId;
    d.serviceCategory = d.serviceCategoryId;
    d.visitDate4List = d.visitDate;
    d.postingDate4List = d.postingDate;


    self.organization2vm = function(val) {
      if (!val) return {};
      return ctx.src.org.first(val);
    };
    self.doctor2vm = function(val) {
      if (!val) return {};
      return ctx.src.persons.get(val);
    };

    self.diagnosis2vm = function(val) {
      if (!val) return {};
      return ctx.src.diagnosis.get(val);
    };

    self.visitDate4List2vm = function(val) {
      return dateLocalFilter(val);
    };
    self.postingDate4List2vm = function(val) {
      return dateLocalFilter(val);
    };
    self.visitDate2vm = function(val) {
      return dateIsoFilter(val);
    };
    self.postingDate2vm = function(val) {
      return dateIsoFilter(val);
    };

    self.service2vm = function(val) {
      if (!val) return {};
      return ctx.src.services.get(val);
    };
    self.serviceCategory2vm = function(val) {
      if (!val) return {};
      return ctx.src.categories.get(val);
    };

    js2vm(self, d);
    self.insuredLabel = d.insuredDto.cardNo + " - " + d.insuredDto.fullName
  };

  export default {
    props: {
      vm: Object
    },
    data: () => ({
      ctx,
      loading: false,
      headers: [
        { text: "", value: "stateKey" },
        {
          text: "Застрахованный",
          align: "start",
          value: "insuredLabel"
        },
        { text: "Мед. организация", value: "organization.fullName" },
        { text: "Дата обращения", sortable: false, value: "visitDate4List" },
        { text: "Услуга", sortable: false, value: "service.name" },
        { text: "Кол-во", sortable: false, value: "quantity" },
        { text: "% Скидка", sortable: false, value: "discountPercent" },
        { text: "Сумма", sortable: false, value: "amount" },
        { text: "Со скидкой", sortable: false, value: "discountedAmount" }
      ],
      items: []
    }),
    watch: {
      "vm.item": function(val) {
        this.items = [];
        if (val) {
          this.fetchItems();
        }
      }
    },
    methods: {
      itemColor(item) {
        if (
          moment(item.visitDate) < moment(item.insuredDto.startDate) ||
          moment(item.visitDate) > moment(item.insuredDto.endDate)
        )
          return "item-red";
        return "";
      },
      async fetchItems() {
        this.items = [];
        this.loading = true;
        let uri;
        if(this.vm.item.isUnspecified) {
          const categoryParameters = this.vm.item.categories.map(c => c.toString()).join();
          uri = `/api/accounting/balance/get-details-by-categories?insuredId=${this.vm.insuredId}&categories=${categoryParameters}`;
        }
        else if (this.vm.item.unionId) {

          uri =
            "/api/accounting/balance/get-detail-by-union?insuredId=" +
            this.vm.insuredId +
            "&unionId=" +
            this.vm.item.unionId;
        } else if (this.vm.item.categoryId) {
          uri =
            "/api/accounting/balance/get-detail-by-category?insuredId=" +
            this.vm.insuredId +
            "&categoryId=" +
            this.vm.item.categoryId;
        } else if (this.vm.item.serviceId) {
          uri =
            "/api/accounting/balance/get-detail-by-service?insuredId=" +
            this.vm.insuredId +
            "&serviceId=" +
            this.vm.item.serviceId;
        } else {
          uri =
            "/api/accounting/balance/get-detail-by-union?insuredId=" +
            this.vm.insuredId +
            "&unionId=";
        }
        const res = await fetch.get(uri);
        if (res) {
          res.forEach(r => {
            this.items.push(new Item(r));
          });
        }
        this.loading = false;
      }
    },

    async mounted() {
      this.vm.detail = this;






      this.loading = true
      await this.fetchItems();
      this.loading = false;
    }
  };
</script>
